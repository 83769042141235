import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  styled,
} from "@mui/material";
import { PhoneInput } from "react-international-phone";
import { requestCall } from "../../api/auth";
import Tabform from "../../common/Tabform";
// import useAgent from "hooks/Agent/useAgent";

const validationSchema = Yup.object({
  name: Yup.string().required("Customer Name is required"),
  phone: Yup.string()
    .required("Phone is required")
    .matches(/^\+\d{1,4}\d{6,}$/gm, "Invalid phone number format"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function CustomizedDialogs({ open, setOpen, data }) {
  //   const { testAgentAction } = useAgent();

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      requestCall({
        extension: values.phone,
      });
    },
  });

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    if (newValue === "3") {
      window.location.href = "/demo";
      return;
    }
    setValue(newValue);
  };
  const CustomTab = styled(Tab)(({ theme }) => ({
    "&.Mui-selected": {
      color: "white", // Set the desired color for the selected tab label
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <Box
          sx={{
            margin: "auto",
            background: "#1a1a1a",
            paddingTop: {
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 3,
            },
            px: {
              xs: 1,
              sm: 1,
              md: 4,
              lg: 5,
              xl: 5,
            },
            paddingBottom: {
              xs: 1,
              sm: 1,
              md: 3,
              lg: 5,
              xl: 5,
            },
            borderRadius: 4,
            // maxHeight: `calc(100vh - 150px)`
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              background: "#313131",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="QuickSmart Features Tab"
              TabIndicatorProps={{
                style: { backgroundColor: "white" },
              }}
            >
              <CustomTab
                label="Try Agent Call"
                value="1"
                sx={{
                  color: "white",
                  textTransform: "inherit",
                  letterSpacing: "1px",
                }}
              />
              {/* <CustomTab
                label="Inbound"
                value="2"
                sx={{
                  color: "white",
                  textTransform: "inherit",
                  letterSpacing: "1px",
                }}
              /> */}
               <CustomTab
                label="Try web call"
                value="3"
                sx={{
                  color: "white",
                  textTransform: "inherit",
                  letterSpacing: "1px",
                }}
              />
              {/* <CustomTab label="Chat" value="3" sx={{ color: "white" }} /> */}
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ pb: 0 }}>
            <Tabform value="Try Agent CAll" />
          </TabPanel>
          {/* <TabPanel value="2" sx={{ pb: 0 }}>
            <Tabform value="inbound" />
          </TabPanel> */}
          <TabPanel value="2" sx={{ pb: 0 }}>
            {/* <Tabform value="Trywebcall" /> */}
            Try web call
          </TabPanel>
          {/* <TabPanel value="3">Item Three</TabPanel> */}
        </Box>
      </TabContext>
    </Box>
  );
}
